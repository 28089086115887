import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, Alert, ListItemText, List, ListItem, ListItemIcon } from "@mui/material";

function Block1() {
    return (
        <>
            <style>
                {`
                    .MuiAlert-icon {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                `}
            </style>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Primera vez
                </Typography><br></br>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia de conducir por primera vez.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 1, mt: 2 }}>
                <Alert severity="info" variant="outlined">
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        · El solicitante deberá presentarse al curso de conducción y aprobar las evaluaciones teórico/práctico en el
                        departamento de eduación vial.
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        · Presentarse 30 minutos antes de su cita con pluma o lápiz.
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        · Es importante CONOCER tu tipo de sangre.
                    </Typography>
                </Alert>
            </Grid>

            <Grid item sm={12} sx={{ mb: 0 }}>
                <Typography variant="h3" style={{ fontWeight: 500 }}>
                    El solicitante  deberá presentar los siguientes requisitos en original:
                </Typography>

                <List sx={{ width: '100%' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Acta de nacimiento (Original)
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Comprobante de pago de validación de aptitudes teóricas y prácticas de conducción de vehículos
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        CURP
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Identificación oficial (INE, pasaporte mexicano, cartilla militar, cédula profesional, en el caso de menores de edad:
                                        credencial escolar vigente, pasaporte mexicano vigente), tendrá que presentarse con papá, mamá o tutor legal.
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Comprobante de domicilio
                                    </Typography>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {<Typography>
                                        Comprobante de domicilio oficial a nombre del interesado, fecha reciente (agua, luz, gas natural, predial o teléfono activo). En caso de no
                                        estar a su nombre, acompañarlo de algún estado de cuenta bancario o de tiendas departamentales con el mismo domicilio del recibo oficial
                                        y/o el INE actualizado. En caso de los menores de edad presentar comprobante de domicilio a nombre del tutor legal.
                                    </Typography>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        En caso de licencia para motociclista presentar (moto y casco) únicamente en Chihuahua capital
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>
                </List>
            </Grid>

            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Automovilista particular: </span>
                        Autoriza al interesado a conducir vehículos particulares hasta de doce plazas, o de carga particular cuyo peso máximo autorizado no exceda de 3.5 Toneladas.
                    </Typography>
                </Grid>

                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Chofer particular: </span>
                        Autoriza al interesado a conducir vehículos particulares destinados al transporte hasta veintidós plazas y un peso máximo de diez toneladas.
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Motociclista: </span>
                        Autoriza al interesado a manejar cualquier tipo de motocicleta.
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px", textAlign: 'center' }}>
                        De acuerdo al artículo 54 de la Ley de Vialidad y Tránsito para el Estado de Chihuahua vigente.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
export default Block1;