import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, Alert, ListItemText, List, ListItem, ListItemIcon } from "@mui/material";

function Block6() {
    return (
        <>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Licencia de conducir para extranjeros
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia de conducir para extranjeros.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 1, mt: 2 }}>
                <Alert severity="info" variant="outlined">
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        Para realizar cualquier trámite, deberás de CONOCER tu CURP y tu tipo de sangre.
                    </Typography>
                </Alert>
            </Grid>

            <Grid item sm={12} sx={{ mb: 0, mt: 2 }}>
                <Typography variant="h3" style={{ fontWeight: 500 }}>
                    El solicitante extranjero deberá presentar los siguientes requisitos en original:
                </Typography>

                <List sx={{ width: '100%' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Pasaporte del país de origen
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Residencia temporal o permanente (FM2 o FM3)
                                    </Typography>
                                </React.Fragment>}

                            secondary={
                                <React.Fragment>
                                    {
                                        <Typography>
                                            · Permiso temporal: Presentar pasaporte de su país, acreditar domicilio y su licencia se autoriza solo por 1 ó 3 años.
                                            <br></br>
                                            · Permiso permanente: Licencia anterior, permiso permanente, acreditar domicilio y su licencia se le autoriza hasta por 6 años.
                                        </Typography>
                                    }
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Sujeto a realizar examen teórico practico en caso de primera vez debe realizar su trámite en la opción de "Primera Vez"
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Comprobante de domicilio
                                    </Typography>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {<Typography>
                                        Comprobante de domicilio oficial a nombre del interesado, fecha reciente (agua, luz, gas natural, predial o teléfono activo). En caso de no
                                        estar a su nombre, acompañarlo de algún estado de cuenta bancario o de tiendas departamentales con el mismo domicilio del recibo oficial
                                        y/o carta de resolución de Migración. En caso de los menores de edad presentar comprobante de domicilio a nombre del tutor legal
                                    </Typography>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </Grid>

            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Automovilista particular: </span>
                        Autoriza al interesado a conducir vehículos particulares hasta de doce plazas, o de carga particular cuyo peso máximo autorizado no exceda de 3.5 Toneladas.
                    </Typography>
                </Grid>

                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Chofer particular: </span>
                        Autoriza al interesado a conducir vehículos particulares destinados al transporte hasta veintidós plazas y un peso máximo de diez toneladas.
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px" }}>
                        <span style={{ color: "#CE0C6A", fontWeight: "bold" }}>Motociclista: </span>
                        Autoriza al interesado a manejar cualquier tipo de motocicleta.
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px", textAlign: 'center' }}>
                        De acuerdo al artículo 54 de la Ley de Vialidad y Tránsito para el Estado de Chihuahua vigente.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
export default Block6;