import React from "react";
import { Fragment } from "react";
import { Grid, Typography, Select, FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import { blue } from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { Controller } from "react-hook-form";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function Final(props) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);

    const [modalMessage, setModalMessage] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const calculateAge = (date) => {
        let birthday = moment(date).utc();
        let now = moment().utc().add(1, "days");
        let age = now.diff(birthday, 'years');
        if (age < 18) {
            setModalMessage("Los menores de 18 años no pueden ser tutores");
            handleOpen();
        }
        props.generalDataController.setTutorAge(age);
    }

    const getData = (curp) => {
        let array = [
            { sex: "", rfc: "", birthdate: null }
        ];

        //sex
        let sex = curp.charAt(10);

        if(sex == 'H'){
            array[0].sex = "M";
        }else if(sex == 'M'){
            array[0].sex = "F";
        }

        //rfc
        array[0].rfc = curp.substring(0, 10);

        //birthdate
        let extracted_birthdate = curp.substring(4, 10);
        let year = extracted_birthdate.substring(0, 2);
        let month = extracted_birthdate.substring(2, 4);
        let day = extracted_birthdate.substring(4, 6);

        if (parseInt(year) >= 0 && parseInt(year) <= 21) {
            year = '20' + year;
        } else {
            year = '19' + year;
        }

        array[0].birthdate = `${year}/${month}/${day}`;
        return array[0];
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                disableEnforceFocus
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h2" component="h2">
                        ¡Advertencia!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                </Box>
            </Modal>

            <Grid item sm={12}>
                <Typography variant="h1">
                    Llena el formulario
                </Typography>
            </Grid>

            <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                <HomeIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Datos de dirección
            </Typography>

            <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="prefix"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                label="Prefijo" {...field}
                                error={!!props.generalDataController.errors.prefix}
                                helperText={props.generalDataController.errors.prefix?.message}>
                                <MenuItem value="Ave.">Ave.</MenuItem>
                                <MenuItem value="Blvd.">Blvd.</MenuItem>
                                <MenuItem value="C.">C.</MenuItem>
                                <MenuItem value="Callejon">Callejon</MenuItem>
                                <MenuItem value="Camino">Camino</MenuItem>
                                <MenuItem value="Carr-KM">Carr-KM</MenuItem>
                                <MenuItem value="Cerrada">Cerrada</MenuItem>
                                <MenuItem value="Conocido">Conocido</MenuItem>
                                <MenuItem value="Par-Ind">Par-Ind</MenuItem>
                                <MenuItem value="Perif.">Perif.</MenuItem>
                                <MenuItem value="Priv">Priv</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <Controller
                        name="street"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField {...field} label="Calle" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.street}
                                helperText={props.generalDataController.errors.street?.message}></TextField>
                        )}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <Controller
                        name="ext_number"
                        control={props.control}
                        render={({ field }) => (
                            <TextField {...field} label="# Exterior" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onKeyDown={
                                    (e) => {
                                        if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                            e.preventDefault();
                                        }
                                    }
                                }
                                error={!!props.generalDataController.errors.ext_number}
                                helperText={props.generalDataController.errors.ext_number?.message}></TextField>
                        )}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <Controller
                        name="int_number"
                        control={props.control}
                        render={({ field }) => (
                            <TextField {...field} label="# Interior" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => removeAccents(e.target.value)}
                                error={!!props.generalDataController.errors.int_number}
                                helperText={props.generalDataController.errors.int_number?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="postal_code"
                        control={props.control}
                        render={({ field }) => (
                            <TextField {...field} label="Código postal" variant="outlined" sx={{ mt: 2 }} fullWidth
                                inputProps={{ maxLength: 5 }}
                                onKeyDown={(e) => {
                                    if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={
                                    (event) => {
                                        let selectedPostalCode = props.generalDataController.selectsData.systemAddresses
                                            .find(systemAddress => systemAddress.code == event.target.value);

                                        if (!selectedPostalCode) {
                                            props.generalDataController.setValue('postal_code_id', '');
                                            props.generalDataController.setValue('state_id', '');
                                            props.generalDataController.setValue('municipality_id', '');
                                            props.generalDataController.setValue('neighborhood_id', '');

                                            props.generalDataController.setSelectsData({
                                                ...props.generalDataController.selectsData,
                                                states: [],
                                                municipalities: [],
                                                neighborhoods: []
                                            });
                                            props.generalDataController.enqueueSnackbar('No se encontró el código postal, intenta de nuevo.', {
                                                variant: 'error'
                                            });
                                            return;
                                        }

                                        props.generalDataController.setSelectsData({
                                            ...props.generalDataController.selectsData,
                                            states: [selectedPostalCode.municipality.state],
                                            municipalities: [selectedPostalCode.municipality],
                                            neighborhoods: selectedPostalCode.neighborhoods
                                        });
                                        props.generalDataController.setValue('postal_code_id', selectedPostalCode.id);
                                        props.generalDataController.setValue('state_id', selectedPostalCode.municipality.state.id);
                                        props.generalDataController.setValue('municipality_id', selectedPostalCode.municipality.id);
                                        props.generalDataController.setValue('neighborhood_id', '');
                                    }
                                }
                                error={!!props.generalDataController.errors.postal_code}
                                helperText={props.generalDataController.errors.postal_code?.message}
                            ></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="state_id"
                        control={props.control}
                        render={({ field }) => (
                            <FormControl sx={{ mt: 2 }} fullWidth>
                                <InputLabel>Estado</InputLabel>
                                <Select {...field} label="Estado" readOnly={true}>
                                    {
                                        props.generalDataController.selectsData.states
                                            .map((state) => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="municipality_id"
                        control={props.control}
                        render={({ field }) => (
                            <FormControl sx={{ mt: 2 }} fullWidth>
                                <InputLabel>Municipio</InputLabel>
                                <Select {...field} label="Municipio" readOnly={true}>
                                    {
                                        props.generalDataController.selectsData.municipalities
                                            .map((municipality) => <MenuItem key={municipality.id} value={municipality.id}>{municipality.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="neighborhood_id"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                label="Colonia" {...field}
                                disabled={props.generalDataController.selectsData.neighborhoods.length == 0}
                                error={!!props.generalDataController.errors.neighborhood_id}
                                helperText={props.generalDataController.errors.neighborhood_id?.message}>
                                {
                                    props.generalDataController.selectsData.neighborhoods
                                        .map((neighborhood) => <MenuItem key={neighborhood.id} value={neighborhood.id}>{neighborhood.name}</MenuItem>)
                                }
                            </TextField>
                        )}
                    />
                </Grid>
            </Grid>

            <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                <ContactEmergencyIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Contacto de emergencia
            </Typography>

            <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="contact_name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField {...field} label="Nombre(s)" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.contact_name}
                                helperText={props.generalDataController.errors.contact_name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="contact_last_name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField {...field} label="Primer Apellido" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.contact_last_name}
                                helperText={props.generalDataController.errors.contact_last_name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="contact_second_last_name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField {...field} label="Segundo Apellido" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.contact_second_last_name}
                                helperText={props.generalDataController.errors.contact_second_last_name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="contact_phone"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                onKeyDown={
                                    (e) => {
                                        if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                            e.preventDefault();
                                        }
                                    }
                                }
                                {...field}
                                label="Teléfono"
                                variant="outlined"
                                inputProps={{ maxLength: 10 }}
                                sx={{ mt: 2 }}
                                fullWidth
                                error={!!props.generalDataController.errors.contact_phone}
                                helperText={props.generalDataController.errors.contact_phone?.message}></TextField>
                        )}
                    />
                </Grid>
            </Grid>
            {
                ((props.generalDataController.getValues('licence_type_id') == 6 ||
                    props.generalDataController.getValues('licence_type_id') == 7) && props.generalDataController.getValues('formality_id') == 1) &&

                <Fragment>
                    <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                        <ConnectWithoutContactIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Tutor del menor
                    </Typography>

                    <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_curp"
                                control={props.control}
                                render={({ field, field: { onChange } }) => (
                                    <TextField {...field} label="Curp" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase())
                                            if (valueWithoutAccents.length == 18) {
                                                let arr = getData(valueWithoutAccents);
                                                    props.generalDataController.setValue('tutor_birthdate', arr.birthdate);
                                                    props.generalDataController.setValue('tutor_sex', arr.sex);
                                                    props.generalDataController.setValue('tutor_rfc', arr.rfc);
                                                    calculateAge(arr.birthdate);
                                            }else if(valueWithoutAccents.length < 18){
                                                props.generalDataController.setValue('tutor_birthdate', null);
                                                props.generalDataController.setValue('tutor_sex', "");
                                                props.generalDataController.setValue('tutor_rfc', "");
                                            }
                                        }}
                                        error={!!props.generalDataController.errors.tutor_curp}
                                        helperText={props.generalDataController.errors.tutor_curp?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_name"
                                control={props.control}
                                render={({ field, field: {onChange} }) => (
                                    <TextField {...field} label="Nombre(s)" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase());
                                        }}
                                        error={!!props.generalDataController.errors.tutor_name}
                                        helperText={props.generalDataController.errors.tutor_name?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_last_name"
                                control={props.control}
                                render={({ field, field: {onChange} }) => (
                                    <TextField {...field} label="Primer Apellido" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase());
                                        }}
                                        error={!!props.generalDataController.errors.tutor_last_name}
                                        helperText={props.generalDataController.errors.tutor_last_name?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_second_last_name"
                                control={props.control}
                                render={({ field, field: {onChange} }) => (
                                    <TextField {...field} label="Segundo Apellido" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase());
                                        }}
                                        error={!!props.generalDataController.errors.tutor_second_last_name}
                                        helperText={props.generalDataController.errors.tutor_second_last_name?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_phone"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        onKeyDown={
                                            (e) => {
                                                if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                                    e.preventDefault();
                                                }
                                            }
                                        }
                                        {...field}
                                        inputProps={{ maxLength: 10 }}
                                        label="Teléfono"
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        error={!!props.generalDataController.errors.tutor_phone}
                                        helperText={props.generalDataController.errors.tutor_phone?.message}></TextField>
                                )}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_email"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField {...field} type="email" label="Correo electrónico" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const valueWithoutAccents = removeAccents(e.target.value);
                                            field.onChange(valueWithoutAccents);
                                        }}  
                                        error={!!props.generalDataController.errors.tutor_email}
                                        helperText={props.generalDataController.errors.tutor_email?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_sex"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        label="Sexo" {...field}
                                        error={!!props.generalDataController.errors.tutor_sex}
                                        helperText={props.generalDataController.errors.tutor_sex?.message}>
                                        <MenuItem value={'M'}>Masculino</MenuItem>
                                        <MenuItem value={'F'}>Femenino</MenuItem>
                                        <MenuItem value={'O'}>Otros</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_rfc"
                                control={props.control}
                                render={({ field, field: { onChange } }) => (
                                    <TextField {...field} label="R.F.C" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        inputProps={{ maxLength: 13 }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase())
                                        }}
                                        error={!!props.generalDataController.errors.tutor_rfc}
                                        helperText={props.generalDataController.errors.tutor_rfc?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_birthdate"
                                control={props.control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Fecha de nacimiento"
                                        {...field}
                                        mask="__/__/____"
                                        onChange={(date => {
                                            date = moment(date).format('YYYY-MM-DD');
                                            field.onChange(date);
                                        })}
                                        disabled
                                        renderInput={(params) =>
                                            <TextField {...params} sx={{ mt: 2 }} fullWidth
                                                inputProps={{ ...params.inputProps, readOnly: true }}
                                                error={!!props.generalDataController.errors.tutor_birthdate}
                                                helperText={props.generalDataController.errors.tutor_birthdate?.message} />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_marital_status"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        label="Estado civil"
                                        {...field}
                                        error={!!props.generalDataController.errors.tutor_marital_status}
                                        helperText={props.generalDataController.errors.tutor_marital_status?.message}>
                                        <MenuItem value={'Soltero'}>Soltero</MenuItem>
                                        <MenuItem value={'Casado'}>Casado</MenuItem>
                                        <MenuItem value={'Divorciado'}>Divorciado</MenuItem>
                                        <MenuItem value={'Separación en proceso judicial'}>Separación en proceso judicial</MenuItem>
                                        <MenuItem value={'Viudo'}>Viudo</MenuItem>
                                        <MenuItem value={'Unión libre'}>Unión libre</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_occupation"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        label="Ocupación"
                                        {...field}
                                        error={!!props.generalDataController.errors.tutor_occupation}
                                        helperText={props.generalDataController.errors.tutor_occupation?.message}>
                                        <MenuItem value={'Empleado'}>Empleado</MenuItem>
                                        <MenuItem value={'Estudiante'}>Estudiante</MenuItem>
                                        <MenuItem value={'Independiente'}>Independiente</MenuItem>
                                        <MenuItem value={'Jubilado'}>Jubilado</MenuItem>
                                        <MenuItem value={'Desocupado'}>Desocupado</MenuItem>
                                        <MenuItem value={'Ama de casa'}>Ama de casa</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_nationality_id"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        ref="tutor_nationality_id"
                                        select
                                        label="Nacionalidad" {...field}
                                        error={!!props.generalDataController.errors.tutor_nationality_id}
                                        helperText={props.generalDataController.errors.tutor_nationality_id?.message}
                                    >
                                        {
                                            props.generalDataController.selectsData.nationalities
                                                .map((nationality) => <MenuItem key={nationality.id} value={nationality.id}>{nationality.name}</MenuItem>)
                                        }
                                    </TextField>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                        <HomeIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Datos de la dirección del tutor
                    </Typography>

                    <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_prefix"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        label="Prefijo" {...field}
                                        error={!!props.generalDataController.errors.tutor_prefix}
                                        helperText={props.generalDataController.errors.tutor_prefix?.message}>
                                        <MenuItem value="Ave.">Ave.</MenuItem>
                                        <MenuItem value="Blvd.">Blvd.</MenuItem>
                                        <MenuItem value="C.">C.</MenuItem>
                                        <MenuItem value="Callejon">Callejon</MenuItem>
                                        <MenuItem value="Camino">Camino</MenuItem>
                                        <MenuItem value="Carr-KM">Carr-KM</MenuItem>
                                        <MenuItem value="Cerrada">Cerrada</MenuItem>
                                        <MenuItem value="Conocido">Conocido</MenuItem>
                                        <MenuItem value="Par-Ind">Par-Ind</MenuItem>
                                        <MenuItem value="Perif.">Perif.</MenuItem>
                                        <MenuItem value="Priv">Priv</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_street"
                                control={props.control}
                                render={({ field,  field: {onChange} }) => (
                                    <TextField {...field} label="Calle" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const valueWithoutAccents = removeAccents(value);
                                            onChange(valueWithoutAccents.toUpperCase());
                                        }}
                                        error={!!props.generalDataController.errors.tutor_street}
                                        helperText={props.generalDataController.errors.tutor_street?.message}></TextField>
                                )}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_ext_number"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField {...field} label="# Exterior" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onKeyDown={
                                            (e) => {
                                                if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                                    e.preventDefault();
                                                }
                                            }
                                        }
                                        error={!!props.generalDataController.errors.tutor_ext_number}
                                        helperText={props.generalDataController.errors.tutor_ext_number?.message}></TextField>
                                )}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_int_number"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField {...field} label="# Interior" variant="outlined" sx={{ mt: 2 }} fullWidth
                                        onChange={(e) => removeAccents(e.target.value)}
                                        error={!!props.generalDataController.errors.tutor_int_number}
                                        helperText={props.generalDataController.errors.tutor_int_number?.message}></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_postal_code"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        inputProps={{ maxLength: 5 }}
                                        {...field}
                                        label="Código postal"
                                        variant="outlined"
                                        sx={{ mt: 2 }} fullWidth
                                        onBlur={
                                            (event) => {
                                                let selectedPostalCode = props.generalDataController.selectsData.systemAddresses
                                                    .find(systemAddress => systemAddress.code == event.target.value);

                                                if (!selectedPostalCode) {
                                                    props.generalDataController.setValue('tutor_postal_code_id', '');
                                                    props.generalDataController.setValue('tutor_state_id', '');
                                                    props.generalDataController.setValue('tutor_municipality_id', '');
                                                    props.generalDataController.setValue('tutor_neighborhood_id', '');

                                                    props.generalDataController.setSelectsData({
                                                        ...props.generalDataController.selectsData,
                                                        tutorStates: [],
                                                        tutorMunicipalities: [],
                                                        tutorNeighborhoods: []
                                                    });
                                                    props.generalDataController.enqueueSnackbar('No se encontró el código postal, intenta de nuevo.', {
                                                        variant: 'error'
                                                    });
                                                    return;
                                                }

                                                props.generalDataController.setSelectsData({
                                                    ...props.generalDataController.selectsData,
                                                    tutorStates: [selectedPostalCode.municipality.state],
                                                    tutorMunicipalities: [selectedPostalCode.municipality],
                                                    tutorNeighborhoods: selectedPostalCode.neighborhoods
                                                });
                                                props.generalDataController.setValue('tutor_postal_code_id', selectedPostalCode.id);
                                                props.generalDataController.setValue('tutor_state_id', selectedPostalCode.municipality.state.id);
                                                props.generalDataController.setValue('tutor_municipality_id', selectedPostalCode.municipality.id);
                                                props.generalDataController.setValue('tutor_neighborhood_id', '');
                                            }
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                                e.preventDefault();
                                            }
                                        }}
                                        error={!!props.generalDataController.errors.tutor_postal_code}
                                        helperText={props.generalDataController.errors.tutor_postal_code?.message}
                                    ></TextField>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_state_id"
                                control={props.control}
                                render={({ field }) => (
                                    <FormControl sx={{ mt: 2 }} fullWidth>
                                        <InputLabel>Estado</InputLabel>
                                        <Select {...field} label="Estado" readOnly={true}>
                                            {
                                                props.generalDataController.selectsData.tutorStates
                                                    .map((state) => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_municipality_id"
                                control={props.control}
                                render={({ field }) => (
                                    <FormControl sx={{ mt: 2 }} fullWidth>
                                        <InputLabel>Municipio</InputLabel>
                                        <Select {...field} label="Municipio" readOnly={true}>
                                            {
                                                props.generalDataController.selectsData.tutorMunicipalities
                                                    .map((municipality) => <MenuItem key={municipality.id} value={municipality.id}>{municipality.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Controller
                                name="tutor_neighborhood_id"
                                control={props.control}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        label="Colonia" {...field}
                                        disabled={props.generalDataController.selectsData.tutorNeighborhoods.length == 0}
                                        error={!!props.generalDataController.errors.tutor_neighborhood_id}
                                        helperText={props.generalDataController.errors.tutor_neighborhood_id?.message}>
                                        {
                                            props.generalDataController.selectsData.tutorNeighborhoods
                                                .map((neighborhood) => <MenuItem key={neighborhood.id} value={neighborhood.id}>{neighborhood.name}</MenuItem>)
                                        }
                                    </TextField>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Fragment>
            }
        </>
    );
}
