import {useState} from 'react'
import { Controller } from 'react-hook-form';
import { Container, Grid, Typography, Select, FormControl, InputLabel, MenuItem, Stack, styled, TextField, FormHelperText, Alert } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { CalendarPicker } from "@mui/x-date-pickers";
import moment from 'moment';
import { PickersDay } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CustomCalendarPicker = styled(CalendarPicker)(
    (props) => 
        `.Mui-disabled {
            background: rgba(255,0,0,0.4);
        }
        .Mui-selected {
            background: rgba(39, 64, 115) !important;
        }
        .Mui-non_working_days{
            background: rgba(170, 170, 170) !important;
        }
        .Mui-past{
            color: rgba(255,0,0) !important;
            background: rgba(240, 240, 240) !important;
        }
        ${props.className != 'no-events' ? `
        .MuiPickersDay-root:not(.Mui-disabled, .Mui-selected) {
            background: rgba(122, 179, 81,0.4) !important;
        }` : ''}
    `    
);


export default function FormOne(props) {
    const [day, setDay] = useState(false);

    return (
        <>
            <Container maxWidth="lg">
                <Grid item sm={12}>
                    <Typography variant="h1">
                        {
                            (() => {
                                let name = "";
                                switch (props.generalDataController.getValues('formality_id')) {
                                    case '1':
                                        name = 'Primera vez';
                                        break;
                                    case '2':
                                        name = 'Renovación';
                                        break;
                                    case '3':
                                        name = 'Canje';
                                        break;
                                    case '4':
                                        name = 'Extravio';
                                        break;
                                    case '5':
                                        name = 'Robo';
                                        break;
                                    case '6':
                                        name = 'Extranjeros';
                                        break;
                                    case '7':
                                        name = 'Foraneos nacional o federal';
                                        break;
                                    case '8':
                                        name = 'Transportes';
                                        break;
                                    default:
                                        name = '';
                                        break;
                                }
                                props.generalDataController.setValue('formality_name', name);
                                return name;
                            })()
                        }
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="h3" style={{ fontWeight: 400 }}>
                        {
                            (() => {
                                switch (props.generalDataController.getValues('formality_id')) {
                                    case '1':
                                        return 'Trámite de licencia de conducir por primera vez.';
                                    case '2':
                                        return 'Trámite de renovación de licencia de conducir.';
                                    case '3':
                                        return 'Trámite para el canje de licencia vigente.';
                                    case '4':
                                        return 'Trámite para el canje de una licencia extraviada.';
                                    case '5':
                                        return 'Trámite para el canje de una licencia que ha sido robada.';
                                    case '6':
                                        return 'Trámite de licencia de conducir para extranjeros.';
                                    case '7':
                                        return 'Trámite de licencia para persona foránea, nacional o federal.';
                                    case '8':
                                        return 'Trámite de licencia para transporte público.';
                                    default:
                                        return '';
                                }
                            })()
                        }
                    </Typography>

                    {
                        (props.generalDataController.watchLicenceTypeId == 4 ||
                            props.generalDataController.watchLicenceTypeId == 5) &&
                        <Grid item sm={12} sx={{ mb: 2, mt: 2 }}>
                            <Alert severity="info" variant="outlined">
                                <Typography variant="h4" style={{ fontWeight: 500 }}>
                                    Por favor comuníquese a la Dirección de Transportes del Estado al tel. 429.33.00 ext. 24909 y 24913 para más información.
                                </Typography>
                            </Alert>
                        </Grid>
                    }
                </Grid>

                <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                    <Grid item sm={12} xs={12} md={6}>
                        <Typography variant="h4">
                            1. Seleccione el tipo de licencia:
                        </Typography>
                        <Controller
                            name="licence_type_id"
                            control={props.control}
                            defaultValue="1"
                            render={({ field }) => (
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    select
                                    label="Tipo de licencia" {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                      }}
                                    error={!!props.generalDataController.errors.licence_type_id}
                                    helperText={props.generalDataController.errors.licence_type_id?.message}>
                                    {
                                        props.generalDataController.selectsData.licenceTypes?.map((licenceType) => <MenuItem key={licenceType.id} value={licenceType.id}>{licenceType.name}</MenuItem>)
                                    }
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6}>
                        <Typography variant="h4">
                            2. Seleccione el módulo de su ciudad:
                        </Typography>
                        <Controller
                            name="module_id"
                            control={props.control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    select
                                    label="Módulo" {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                      }}
                                    error={!!props.generalDataController.errors.module_id}
                                    helperText={props.generalDataController.errors.module_id?.message}>
                                    {   props.generalDataController.watchLicenceTypeId == 4 || props.generalDataController.watchLicenceTypeId == 5 ?
                                            <MenuItem key={1} value={1}>CHIHUAHUA</MenuItem>
                                        : props.generalDataController.selectsData.modules?.map((module) => <MenuItem key={module.id} value={module.id}>{module.name}</MenuItem>)
                                    }
                                </TextField>
                            )}
                        />
                    </Grid>
                    {props.generalDataController.getValues('module_id') && props.generalDataController.getValues('licence_type_id') ? (
                    <Grid item xs={12} sm={12} md={6} sx={{ mt: 2 }}>
                            <Typography variant="h4" paddingTop={{ sm: 0, xs: 5 }}>
                                3. Seleccione una fecha disponible:
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <Controller
                                        name="appointment_date"
                                        control={props.control}
                                        render={({ field, field: { onChange } }) => (
                                            <FormControl fullWidth sx={{
                                                mt: 2,
                                                pointerEvents: !props.generalDataController.watchLicenceTypeId || !props.generalDataController.watchModuleId ? 'none' : 'auto'
                                            }}>
                                            <CustomCalendarPicker
                                                {...field}
                                                className={!props.generalDataController.watchLicenceTypeId || !props.generalDataController.watchModuleId ? "no-events" : ""}
                                                date={props.generalDataController.selectedCalendarDate}
                                                shouldDisableDate={(date)=>
                                                    props.generalDataController.selectsData.unavailableDays.includes(moment(date).utc().date()+"") || props.generalDataController.selectsData.nonworkingDays.includes(moment(date).utc().date()+"")
                                                }
                                                disablePast={true}
                                                renderDay={(day, selectedDays, pickersDayProps) => {
                                                    let selectedMuiClass = '';
                                                    if ( props.generalDataController.selectsData.nonworkingDays.includes( moment(day).utc().date()+"" ) )
                                                        selectedMuiClass = 'Mui-non_working_days';

                                                    if(moment(day).utc() > moment(new Date().setMonth((new Date()).getMonth() + 3)).utc())
                                                        selectedMuiClass = 'Mui-past';

                                                    if(moment().utc() > moment(day).utc())
                                                        selectedMuiClass = 'Mui-past';
                                                    return (
                                                        <PickersDay
                                                            className={ selectedMuiClass }
                                                            { ...pickersDayProps }
                                                        /> 
                                                    );
                                                }}
                                                maxDate={new Date().setMonth((new Date()).getMonth() + 3)}
                                                onYearChange={(date) => {
                                                    props.generalDataController.setSelectedCalendarDate(null);
                                                    props.generalDataController.setSelectedYear(moment(date).utc().year())
                                                }}
                                                onMonthChange={(date) => {
                                                    props.generalDataController.setSelectedCalendarDate(null);
                                                    props.generalDataController.setSelectedMonth(moment(date).utc().month() + 1);
                                                    props.generalDataController.setSelectedYear(moment(date).utc().year());
                                                }}
                                                onChange={(date) => {
                                                    props.generalDataController.setSelectedCalendarDate(date);
                                                    onChange(moment(date).utc().format('YYYY-MM-DD'));
                                                    props.generalDataController.setValue('appointment_time', '');
                                                    props.generalDataController.setSelectsData({
                                                        ...props.generalDataController.selectsData,
                                                        availableHours: props.generalDataController.selectsData.calendarDates[moment(date).utc().date()]
                                                    });
                                                    setDay(true);
                                                }}
                                            /> 
                                            <FormHelperText error={!!props.generalDataController.errors.appointment_date}>
                                                {props.generalDataController.errors.appointment_date?.message}
                                            </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Stack direction="row" sx={{ my: 1 }}><CircleIcon sx={{ color: "#283A63" }} /> &nbsp; Seleccionado</Stack>
                                    <Stack direction="row" sx={{ my: 1 }}><CircleIcon sx={{ color: "#7AB351" }} /> &nbsp; Disponible</Stack>
                                    <Stack direction="row" sx={{ my: 1 }}><CircleIcon sx={{ color: "#d10000" }} /> &nbsp; Cupo lleno</Stack>
                                    <Stack direction="row" sx={{ my: 1 }}><CircleIcon sx={{ color: "#AAAAAA" }} /> &nbsp; Día sin servicio</Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null }
                    {props.generalDataController.getValues('appointment_date') ? (
                        <Grid item sm={12} xs={12} md={6} sx={{ mt: 2 }}>
                            <Typography variant="h4" paddingTop={{ sm: 0, xs: 5 }}>
                                4. Seleccione la hora de cita:
                            </Typography>
                            <Controller
                                name="appointment_time"
                                control={props.control}
                                defaultValue="1"
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 2 }}
                                        fullWidth
                                        select
                                        disabled={props.generalDataController.selectsData.availableHours.length == 0}
                                        label="Hora"
                                        {...field}
                                        error={!!props.generalDataController.errors.appointment_time}
                                        helperText={props.generalDataController.errors.appointment_time?.message}>
                                        {
                                            props.generalDataController.selectsData.availableHours
                                                .map((hour) => <MenuItem key={hour} value={hour}>{hour}</MenuItem>)
                                        }
                                    </TextField>
                                )}
                            />
                        </Grid>
                    ):null}
                </Grid>
            </Container>
        </>
    );
}
