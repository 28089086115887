import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, ListItemText, Alert, List, ListItem, ListItemIcon } from "@mui/material";

function Block8() {
    return (
        <>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Licencia de transporte público
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia para transporte público.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 1, mt: 2 }}>
                <Alert severity="info" variant="outlined">
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        Para realizar cualquier trámite, deberás de CONOCER tu CURP y tu tipo de sangre.
                    </Typography>
                </Alert>
            </Grid>

            <Grid item sm={12} sx={{ mb: 2, mt: 2 }}>
                <Typography variant="h3" style={{ fontWeight: 500 }}>
                    Para el trámite de licencia de transportes por <b>primera vez</b>, acuda con la papelería solicitada la secretaría de transportes localizada en Av. Homero y Tecnológico estación norte del “Bowi”, una vez aprobada la solicitud, acuda al módulo de licencias ubicado en el Periférico Ortiz Mena y Periférico de la Juventud.
                    <br/><br/>
                    Para la <b>renovación</b> de su licencia, acuda con la papelería solicitada la secretaría de transportes en la dirección previamente mencionada, una vez aprobada la solicitud, agende su cita a través de este sitio seleccionando el tipo de trámite como renovación.                
                </Typography>
            </Grid>
        </>
    );
}
export default Block8;