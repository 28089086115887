import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import Authenticated from "src/components/Authenticated";

import SuspenseLoader from "src/components/SuspenseLoader";
import EmailVerificationRequired from "src/pages/Auth/EmailVerificationRequired";
import ResetPassword from "src/pages/Auth/ResetPassword";
import VerifyEmail from "src/pages/Auth/VerifyEmail";
import Info from "src/pages/Info";
import Process from "src/pages/Process";
import HighschoolProcesses from "src/pages/HighschoolProcesses";
import GodaddyIdentifier from "src/pages/GodaddyIdentifier";

const Loader = (Component: any) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Status

const Status403 = Loader(lazy(() => import("src/pages/Status/Status403")));
const Status404 = Loader(lazy(() => import("src/pages/Status/Status404")));
const Status500 = Loader(lazy(() => import("src/pages/Status/Status500")));
const StatusComingSoon = Loader(
  lazy(() => import("src/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/pages/Status/Maintenance"))
);
const Login = Loader(lazy(() => import("src/pages/Auth/Login")));
const Register = Loader(lazy(() => import("src/pages/Auth/Register")));
const RecoverPassword = Loader(lazy(() => import("src/pages/Auth/RecoverPassword")));
const Highschool = Loader(lazy(() => import("src/pages/Highschool")));

const baseRoutes = [
  {
    path: "",
    element: <Navigate to="/info" replace />,
  },
  /* {
    path: "/highschool",
    element: <Highschool />,
  }, */
  {
    path: '/login',
    element: <Login />
  },
  /* {
    path: '/register',
    element: <Register />
  }, */
  {
    path: '/recover-password',
    element: <RecoverPassword />
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />
  },
  {
    path: '/email-verification/:id/:token/:expires/:signature',
    element: <Authenticated><VerifyEmail /></Authenticated>
  },
  {
    path: "status",
    children: [
      {
        path: "",
        element: <Navigate to="404" replace />,
      },
      {
        path: "403",
        children: [
          {
            path: '',
            element: <Status403 />,
          },
          {
            path: 'email',
            element: <Authenticated><EmailVerificationRequired /></Authenticated>,
          }
        ]
      },
      {
        path: "404",
        element: <Status404 />,
      },
      {
        path: "500",
        element: <Status500 />,
      },
      {
        path: "maintenance",
        element: <StatusMaintenance />,
      },
      {
        path: "coming-soon",
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: 'info',
    element: <Info />
  },
  {
    path: 'process',
    element: <Process />
  },
  {
    path: 'hg-process',
    element: <HighschoolProcesses />
  },
  {
    path: '.well-known/pki-validation/godaddy.html',
    element: <GodaddyIdentifier />
  },
  {
    path: "*",
    element: <Status404 />,
  },
];

export default baseRoutes;
